@import url(../node_modules/destyle.css/destyle.css);

@font-face {
  font-family: "HKGrotesk-Bold";
  src: url("fonts/HKGrotesk-Bold.woff2") format("woff2"),
    url("fonts/HKGrotesk-Bold.woff") format("woff");
}

@font-face {
  font-family: "HKGrotesk-SemiBold";
  src: url("fonts/HKGrotesk-SemiBold.woff2") format("woff2"),
    url("fonts/HKGrotesk-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "GT-Haptik-Regular-Rotalic";
  src: url("fonts/GT-Haptik-Regular-Rotalic.woff2") format("woff2"),
    url("fonts/GT-Haptik-Regular-Rotalic.woff") format("woff");
}

html,
body {
  min-height: 100vh;
}

body {
  background-color: white;
  color: black;
}

h1 {
  font-family: "HKGrotesk-Bold", sans-serif;
  font-size: 1.25rem;
  line-height: 5;
}

p,
a {
  margin-bottom: 1em;
  font-family: "HKGrotesk-SemiBold", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 1px;

  @media only screen and (min-width: 768px) {
    font-size: 1.25rem;
  }
}

.content {
  padding: 3rem 0 10rem;

  @media only screen and (min-width: 768px) {
    padding-top: 5rem;
  }
}

.frame {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 768px) {
    height: 100vh;
  }

  &-content {
    width: 100%;
    text-align: center;
  }
}

figure {
  width: 100%;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.posters {
  width: 100%;
  margin: 0 auto;

  @media only screen and (min-width: 768px) {
    max-width: 1440px;
  }

  &-wrapper {
    width: 100%;
    margin: 3rem 0 5rem;
    position: relative;

    @media only screen and (min-width: 768px) {
      margin-top: 5rem;
    }

    button {
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      font-size: 0;

      &.prev {
        left: 0;
      }

      &.next {
        right: 0;
      }
    }
  }
}

.writeup {
  max-width: 640px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: center;

  @media only screen and (min-width: 768px) {
    padding: 0;
  }
}

ul {
  margin: 5rem 0;

  a {
    font-family: "GT-Haptik-Regular-Rotalic", sans-serif;
    font-size: 1.5rem;
    line-height: 1.875;
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
      font-size: 2rem;
    }
  }

  + img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }
}
